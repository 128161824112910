
export function validateEmail(email) {
  return /^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(email)
};


function validatePhone(phone) {
  return /^((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}$/.test(phone)
};


export function setPhoneValue (event) {
  const newChar = event.target.value.slice(-1)
  if (!isNaN(parseInt(newChar, 10))) {
    const strictNum = document.getElementById('phone').value.match(/\d/g).join("");
    if (strictNum.length > 10){
      document.getElementById('phone').value = event.target.value.slice(0, -1);
      return
    }
    if(strictNum.length >= 6 ){
      document.getElementById('phone').value = "("+ strictNum.slice(0,3) +") " + strictNum.slice(3,6) +"-" + strictNum.slice(6)
    }
    if(strictNum.length >= 3 && strictNum.length < 6){
      document.getElementById('phone').value = "("+ strictNum.slice(0,3) +") " + strictNum.slice(3)
    }
    const form = this.state.form;
    form[event.target.name] = event.target.value;
    const valid = this.state.valid;
    valid.errors[event.target.name] = false
    this.setState({form, valid})
  } else {
    document.getElementById('phone').value = event.target.value.slice(0, -1);
  }
}

export function validateProviderForm(form){
  const {name, email, phone} = form
  const errors = {};
  let success = true;
  if (name === undefined || name === "") {
    success = false;
    errors.name = true;
  }
  if (!validateEmail(email)) {
    success = false;
    errors.email = true;
  }
  if (!validatePhone(phone)) {
    success = false;
    errors.phone = true;
  }
  return {
    success,
    errors
  };
};