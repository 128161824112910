import React from 'react'
import {validateProviderForm, setPhoneValue} from '../util/validation'
import {apiPostContact} from '../lib/mcclient'
import Recaptcha from 'react-google-invisible-recaptcha';

export default class ProviderForm extends React.Component{
  constructor(props, context) {
    super(props, context);
    this.state = {
      form: { type:"provider"},
      response: { data: { success: false } },
      valid:{
        success: true,
        errors:{}
      }
    }
    this.setPhoneValue = setPhoneValue.bind(this);
    this.onResolved = this.onResolved.bind(this);
  };

  handleChange(event) {
    const form = this.state.form;
    form[event.target.name] = event.target.value;
    const valid = this.state.valid;
    valid.errors[event.target.name] = false
    this.setState({form, valid})
  }

  handleSubmit(){
    let valid = validateProviderForm(this.state.form)
    this.setState({valid: valid})
    if(valid.success){
      this.recaptcha.execute();
    }
  }

  async onResolved(){
    const form = this.state.form;
    form["recaptchaResponse"] = this.recaptcha.getResponse();
    this.setState({form});
    const response = await apiPostContact(this.state.form)
    this.setState({response})
  }

  returnCss(prop){
    if(this.state.valid.errors[prop]){
      return "form-control error-border"
    }
    return "form-control"
  };

  render(){
    if(this.state.response.data.success === false){
      return (
        <div className={"d-flex flex-column justify-content-center"}>
          <div className={"form-spacing"}>
            <input className={this.returnCss("name")} placeholder="Name" name="name" id={"name"} onChange={(e)=> this.handleChange(e)}/>
            {this.state.valid.errors["name"] &&
            <label htmlFor={"name"} className={"error-label"}>Please provide your name.</label>
            }
          </div>
          <div className={"form-spacing"}>
            <input className={this.returnCss("email")} placeholder="Email" id={"email"} name="email" onChange={(e)=> this.handleChange(e)}/>
            {this.state.valid.errors["email"] &&
            <label htmlFor={"email"} className={"error-label"}>Please provide a valid email address.</label>
            }
          </div>
          <div className={"form-spacing"}>
            <input className={this.returnCss("phone")} placeholder="Phone" name="phone" id="phone" onChange={this.setPhoneValue}/>
            {this.state.valid.errors["phone"] &&
            <label htmlFor={"phone"} className={"error-label"}>Please provide a valid phone number.</label>
            }
          </div>
          <div className={"form-spacing"}>
            <textarea placeholder="Description" name={"description"} className="form-control" rows="3" onChange={(e)=>this.handleChange(e)}/>
          </div>
          <div className={"text-right"}>
            <button type="button" className="btn btn-primary" onClick={()=> this.handleSubmit()}>Submit›</button>
            <Recaptcha
              ref={ ref => this.recaptcha = ref }
              sitekey={process.env.GATSBY_GOOGLE_CLIENT}
              onResolved={ this.onResolved }
            />
          </div>
        </div>
      )
    } else {
      return (
        <div className={"spacing text-center"}>
          <label htmlFor={"provider-send"}>Get ready for a collections tsunami!  Someone from the team will reach out as soon as possible.</label>
          <div id={"provider-send"}>
            <img style={{width:"75px", transform: "rotate(-35deg)"}} className="img-logo" src={"/send.svg"} alt={"sendit"}/>
          </div>
        </div>
      )
    }

  }
}