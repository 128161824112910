import axios from 'axios'

export async function apiPostContact (data) {
  return await axios({
    headers:{
      'content-type': 'application/json'
    },
    method: 'POST',
    url: process.env.GATSBY_MC_BACKEND + `/email`,
    data
  }).then((res) => {
    return res
  }).catch(error => {
    return error.response.data
  })
}

