import React from "react"
import Layout from "../components/layout"
import "./providers.css"
import {isMobile} from "react-device-detect"
import ProviderForm from "../components/providerform"
import SEO from "../components/seo"


function getWidth(){
  if (isMobile){
    return {"width":"90%"}
  } else {
    return {"width":"40%"}
  }
}

function getMobileOrder(){
  if (isMobile){
    return "order-last"
  } else{
    return ""
  }
}

export default () => {
  return (
    <Layout>
      <SEO
        title={"Cashback·Medical - For medical providers"}
        description={"Start collecting patient revenue!  Medical debt collections and medical debt recovery"}
      />
      <div className="mc-page container-fluid">
        <div className="row">
          <div style={{marginBottom:"75px"}} className="col-md-12">
            <div>
              <div className="accent-div"/>
              <h1>Medical debt collections</h1>
              <div className={"spacing"}>
                <p>Cashback·Medical is a payment app that <b>incentivizes</b> patients to pay their medical bills. Medical debt can be a sensitive and stressful issue. Cashback·Medical strives to provide HIPAA-compliant patient debt collection services that positively affects the patient's relationship with your medical office.</p>
              </div>
            </div>
          </div>
        </div>
        <div className={"row"}>
          <div style={{marginBottom:"75px"}} className={"col-md-12"}>
            <div className="text-center">
              <img style={getWidth()} alt={"provider-page"} src={"/phone2_new.png"}/>
            </div>
          </div>
        </div>
        <div className="row spacing">
          <div style={{marginBottom:"75px"}} className="col-md-7">
            <div>
              <div className="accent-div"/>
              <h1>HIPAA Compliant</h1>
              <div className={"spacing"}>
                <p>Our team is trained in HIPAA regulations and we take great care to handle personal health information with the utmost respect and confidentiality. We use encrypted systems and secure data storage to protect your information from unauthorized access.</p>
              </div>
            </div>
          </div>
          <div className={"col-md-5"}>
            <div className="text-center">
              <img style={{"width":"80%"}} alt={"provider-page"} src={"/hipaa-logo-2.png"}/>
            </div>
          </div>
        </div>
        <div className="row spacing">
          <div className={"col-md-5 " + getMobileOrder()}>
            <div className="text-center">
              <img style={{"width":"80%"}} alt={"provider-page"} src={"/patient-medical-bill.jpeg"}/>
            </div>
          </div>
          <div style={{marginBottom:"75px"}} className="col-md-7">
            <div>
              <div className="accent-div"/>
              <h1>Patient relationships</h1>
              <div className={"spacing"}>
                <p>We understand the importance of maintaining a positive relationship between patients and healthcare providers. We approach debt collection with empathy and professionalism, Cashback Medical's incentive based approach provides great value to the patient to keep them physically and financially healthy.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="spacing row">
          <div className="col-md-12">
            <div>
              <div className="accent-div"/>
              <h1>Collect more patient revenue</h1>
              <div className={"spacing"}>
                <p>Insurers are assigning more financial responsibility onto patients with high-deductible plans and higher out-of-pocket maximums.  Providers work with <b>Cashback·Medical</b> to increase collections from the growing portion of <b>patient self-pay revenue</b>.</p>
                <p>Let's talk about your past-due patient accounts.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div style={{marginBottom:"75px"}} className="col-md-12">
            <div>
              <div className={"spacing"}>
                <ProviderForm/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}